export function initVideoChapterPlayback() {
  const player = document.querySelector('.video-container iframe')
  const timeline = document.querySelector('#chapters')

  if (!player || !timeline) {
    return
  }

  timeline.addEventListener('click', (event) => {
    const link = event.target.closest('a')
    if (!link) {
      return
    }

    event.preventDefault()
    const start = new URL(link.href).searchParams.get('t')
    player.setAttribute(
      'src',
      tweakPlayerURL({ url: player.getAttribute('src'), start })
    )
  })
}

function tweakPlayerURL({ url, start }) {
  url = new URL(url)
  url.searchParams.set('start', start)
  url.searchParams.set('autoplay', 1)
  return url.toString()
}
