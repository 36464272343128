// Algolia search UI

import { createRoot } from 'react-dom/client'

import Search from './Search'

export function loadSearch() {
  const container = document.getElementById('reactAlgolia')
  if (!container) {
    return
  }
  const root = createRoot(container)

  // data-page-size lets us sync Algolia's pagination with our server-rendered
  // page size, to avoid cloaking issues, among other things.
  const pageSize = Number(container.dataset.pageSize || 12)
  // data-required-tag lets us pre-filter (require/exclude) a tag (e.g. so we
  // can split between videos and others).
  const requiredTag = container.dataset.requiredTag

  root.render(<Search pageSize={pageSize} requiredTag={requiredTag} />)
}
