// The main Algolia search UI.  Makes best use of Algolia's built-in components,
// so we don't need to rewire everything.

import algoliasearch from 'algoliasearch/lite'
import {
  Configure,
  InstantSearch,
  Pagination,
  RefinementList,
  SearchBox,
} from 'react-instantsearch-dom'
import { useEffect } from 'react'

import { createURL, useURLSync } from './url-sync'
import CustomHits from './CustomHits'
import { LANG, TRANSLATIONS } from './constants'
import { tweakTags } from './tags'

// The core client to Algolia's API.  Environment variables are selectively
// exposed by Webpack from its own dotenv-based keys.
const searchClient = algoliasearch(
  process.env.ALGOLIA_APPLICATION_ID,
  process.env.ALGOLIA_SEARCH_API_KEY
)

// The root component for the search UI.
//
// @prop {Number} pageSize=12 Lets us ensure we sync Algolia pagination with the
// server-rendered one, by using identical page sizes (and ordering).
//
// @prop {String} requiredTag (optional) Lets us pre-filter the list to require
// or exclude tags from the listing.  As our site splits our data into two
// buckets (videos and others), we can use positive ('Cours vidéos') and
// negative ('-Cours vidéos') values for this according to the container page.
export default function Search({ pageSize = 12, requiredTag = null }) {
  const { reactToStateChange, searchState } = useURLSync()

  const configProps = { hitsPerPage: pageSize }
  if (requiredTag) {
    configProps.tagFilters = requiredTag
  }

  // Strip server-rendered items once we're mounted, to avoid duplicate
  // contents in the page.
  useEffect(() => {
    document.getElementById('hits')?.remove()
    document.getElementById('pagination')?.remove()
  }, [])

  return (
    <InstantSearch
      createURL={createURL}
      indexName={`di_${LANG}`}
      // The searchState controlled-component aspect lets us URL sync
      onSearchStateChange={reactToStateChange}
      searchClient={searchClient}
      searchState={searchState}
    >
      <Configure {...configProps} />
      <SearchBox autoFocus reset={null} translations={TRANSLATIONS.searchBox} />
      <RefinementList
        attribute="_tags"
        limit={7}
        transformItems={tweakTags(requiredTag)}
      />
      <CustomHits hideTag={requiredTag} />
      <Pagination
        padding={4}
        showFirst={false}
        translations={TRANSLATIONS.pagination}
      />
    </InstantSearch>
  )
}
