// Utilities for our Algolia code.

import { LANG } from './constants'

const DATE_TIME_FORMATTERS = {
  iso: { format: (ref) => ref.toISOString().split('T')[0] },
  date: new Intl.DateTimeFormat(LANG, { dateStyle: 'full' }),
}

// Formats a date/time (moslty dates), e.g. for hit rendering.
//
// @param {Number|String|Date} ref The date/time to format.  @param
// {'iso'|'date'} format='date' the formatter to use.
//
// - 'iso' format is an ISO8601 date ('YYYY-Mm-DD').
// - 'date' format is a long-form FR date (e.g. 'mercredi 3 février 2022').
export function formatDateTime(ref, format = 'date') {
  if (typeof ref === 'number') {
    ref = new Date(ref * 1000)
  }
  return DATE_TIME_FORMATTERS[format].format(ref)
}
