import { initEventTracking } from './track-events'
import { initInternalNavigation, initMobileNav } from './navigation'
import { initSharing } from './sharing'
import { initVideoChapterPlayback } from './youtube-playback.js'
import { loadSearch } from './algolia'

loadSearch()
initMobileNav()
initEventTracking()
initInternalNavigation()
initVideoChapterPlayback()
initSharing()
