// Show & hide nav
function mobileNav(button, target) {
  button = document.getElementById(button)
  target = document.getElementById(target)
  const topLevelHeader = document.querySelector('.header-toplevel')
  const logo = topLevelHeader.querySelector('#logo')
  const mainNav = topLevelHeader.querySelector('#nav')

  if (button.length === 0 || target.length === 0) {
    return
  }

  const content = button.querySelector('.content')

  button.addEventListener('click', () => {
    const wasCollapsed = content.textContent === 'Ouvrir le menu'
    content.textContent = wasCollapsed ? 'Fermer le menu' : 'Ouvrir le menu'

    button.toggleAttribute('aria-expanded')
    button.classList.toggle('is-active')
    target.classList.toggle('nav-is-collapsed')
    target.classList.toggle('nav-is-displayed')
    if (wasCollapsed) {
      topLevelHeader.insertAdjacentElement('afterend', mainNav)
    } else {
      logo.insertAdjacentElement('afterend', mainNav)
    }
  })
}

class TabEvent extends Event {
  constructor(tab) {
    super('tabchange')
    this.tab = tab
  }
}

export function initMobileNav() {
  const jsButton = document.createElement('button')
  jsButton.setAttribute('type', 'button')
  jsButton.setAttribute('aria-expanded', false)
  jsButton.setAttribute('aria-controls', 'nav')
  jsButton.setAttribute('id', 'toggle-nav')
  jsButton.setAttribute('class', 'hamburger')
  jsButton.insertAdjacentHTML(
    'beforeend',
    `<span class="content">Ouvrir le menu</span>
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>`
  )
  const navContainer = document.querySelector('.header-toplevel')
  navContainer.insertAdjacentElement('beforeend', jsButton)
  document.getElementById('header').classList.add('nav-is-collapsed')

  mobileNav('toggle-nav', 'header')
}

// TRAINING INTERNAL NAVIGATION
// ----------------------------

export function initInternalNavigation() {
  const mainContainer = document.querySelector('.training .inner-wrapper')
  if (!mainContainer) return

  const trainingNav = mainContainer.querySelector('nav')
  const firstLink = trainingNav.querySelector('a')
  const allTabs = Array.from(mainContainer.querySelectorAll('div.tab'))

  // Monitor "stuck status" of the nav tabs: when they're stuck to the top of
  // the viewport it means we need to scroll back up when switching tabs, to
  // show the tab content's top.

  let needsSnapping = false

  new IntersectionObserver(
    ([e]) => {
      needsSnapping = e.intersectionRatio < 1
    },
    { threshold: [1] }
  ).observe(trainingNav)

  // Tile tweak setup

  const SEP = ' — '
  const baseTitle = document.title

  // Active link initialization

  let activeLink = setupInitialActiveLink()

  // Although no-JS, pure-CSS solutions will work as a fallback, they will
  // result in a scroll to the top of the tab element, hiding the tabs (until we
  // figure some other desktop styling to avoid that).  So we hijack nav link
  // clicks to (a) toggle active classes (which CSS-only won't do) and (b)
  // manually update the URL and force `:target` style recomputation. We also
  // adjust doc title for cleaner navigation.
  trainingNav.addEventListener('click', (event) => {
    const link = event.target.closest('a')
    if (!link || !history.pushState) return

    event.preventDefault()

    removeForcedStyles()
    activeLink?.classList.remove('active')
    activeLink = link
    adjustState()

    // Because `pushState` triggers no external state changes (no `hashchange`
    // event, no `:target` style examination), we push twice then `back()`, as
    // this latter call *does* trigger external state changes.
    history.pushState({}, null, link.href)
    history.pushState({}, null, link.href)
    history.back()
  })

  // Other links to non-tabbed parts may happen, in which case we need to hide
  // any visible tab and de-activate any active tab link.
  window.addEventListener('hashchange', () => {
    const hash = window.location.hash.slice(1)
    let link = null
    if (hash) {
      link = trainingNav.querySelector(`a[href$="${hash}"`)
    }
    removeForcedStyles()
    if (!link) {
      activeLink?.classList.remove('active')
      activeLink = null
    }
  })

  // This is used both at init and tab-switch times for enabling the tab,
  // possibly scrolling it back to top, and tweaking the doc title.
  function adjustState() {
    activeLink?.classList.add('active')
    // Due to sticky positioning, there may be a current scroll in effect when
    // we switch tab, and we should reset the main container's scroll position
    // to start at the top of the new tab's content (if there is indeed a tab).
    if (activeLink && needsSnapping) {
      mainContainer.scrollIntoView()
    }
    const prefix =
      activeLink === firstLink || !activeLink ? null : activeLink.innerText
    document.title = prefix ? `${prefix}${SEP}${baseTitle}` : baseTitle
    const tab = activeLink?.href.split('#')[1] ?? 'summary'
    document.dispatchEvent(new TabEvent(tab))
  }

  function removeForcedStyles() {
    for (const tab of allTabs) {
      tab.style.display = ''
    }
  }

  function setupInitialActiveLink() {
    const hash = window.location.hash.slice(1)
    if (hash) {
      activeLink = trainingNav.querySelector(`a[href$="${hash}"`)
    }
    if (!activeLink) {
      activeLink = firstLink
      // We actually don't have a hash and are defaulting to first tab: we need
      // to force display of the first tab as CSS alone won't (it's not
      // `:target`).  remove that on actual tab click later.
      const activeTab = allTabs.find((tab) => activeLink.href.endsWith(tab.id))
      if (activeTab) activeTab.style.display = 'block'
    }

    adjustState()

    return activeLink
  }
}
