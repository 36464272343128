// Our custom implementation of the hits-rendering component.  Algolia's doesn't
// render the kind of container we want (it misses some critical classes we
// need), so we also customize the Hits component.

import { connectHits } from 'react-instantsearch-dom'

import { formatDateTime } from './utils'
import { getTagImageNode } from './tags'

// The main hits container.  The `hideTag` prop lets us ensure a specific tag
// isn't rendered in the hits' tag lists (e.g. when we pre-filter the whole
// list, so all hits have that tag).
function DIHits({ hits, hideTag }) {
  return (
    <ul className="l-grid l-grid__3cols">
      {hits.map((hit) => (
        <Hit key={hit.objectID} hideTag={hideTag} hit={hit} />
      ))}
    </ul>
  )
}
// As we can't default export a const on-the-fly, it's going to be
// default-exported at the end of this file.
const CustomHits = connectHits(DIHits)

// The component rendering an individual hit.  This is always a custom
// implementation (Algolia's default rendering is just a JSON excerpt).
function Hit({ hit, hideTag }) {
  // Craft a nice tag list
  const tags = hit._tags
    ?.filter((tag) => tag !== hideTag)
    .map((tag) => getTagImageNode(tag, true) || <span key={tag}>{tag}</span>)
    // Pretty hacky way of ensuring images are before texts, and texts are sorted
    .sort(({ key: k1, type: t1 }, { key: k2, type: t2 }) => {
      return t1.localeCompare(t2) || k1.localeCompare(k2)
    })

  // Dev hack: live-replace URLs to route to the local dev server for easier
  // navigation.
  const url =
    process.env.NODE_ENV === 'production'
      ? hit.url
      : hit.url.replace(
          'https://delicious-insights.com',
          window.location.origin
        )

  return (
    <li className="c-card c-card__image">
      <article>
        <a className="c-card__link" href={url}>
          {hit.image && (
            <img
              className="c-card__media"
              src={hit.image}
              loading="lazy"
              alt={hit.title}
            />
          )}
          <div className="c-card__tags">{tags}</div>
          <h3 className="c-card__title">{hit.title}</h3>
          {String(hit.description || '').trim() != '' && (
            <p
              className="c-card__content"
              dangerouslySetInnerHTML={{ __html: hit.description }}
            />
          )}
          <footer className="c-card__meta">
            <time dateTime={formatDateTime(hit.date, 'iso')}>
              {formatDateTime(hit.date, 'date')}
            </time>
            <span className="c-card__reading-time">{hit.readingTime}</span>
          </footer>
        </a>
      </article>
    </li>
  )
}

export default CustomHits
