export function initEventTracking() {
  const sessionList = document.querySelector('ul.session-list')
  if (!sessionList) return

  const { label, minPrice } = sessionList.dataset

  sessionList.addEventListener('click', (event) => {
    if (!event.target.closest('a')) {
      return
    }

    envEvent({
      event_category: 'trainings',
      event_action: 'contract-request',
      event_label: label,
      value: Number(minPrice) || 0,
    })
  })

  // Custom event
  document.addEventListener('tabchange', ({ tab }) => {
    envEvent({
      event_category: 'trainings',
      event_action: 'tab-change',
      event_label: tab,
    })
  })

  function envEvent(options) {
    if (window.location.hostname.endsWith('delicious-insights.com')) {
      gtag('event', options)
    } else {
      console.debug('event', options)
    }
  }
}
