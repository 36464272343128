// Various constants used by our Algolia code.

import arrowLeft from '../../../_includes/svg/arrow-left.svg'
import arrowRight from '../../../_includes/svg/arrow-right.svg'

// The language to use for translations (and index matching)
export const LANG = document.documentElement.lang?.toLowerCase() || 'fr'

// Custom translations for the various components used.  We only list
// translations we actually use.  Not used directly: the exported TRANSLATIONS
// constant hoisted the current-language values for direct use under primary
// keys (this way, external code doesn't have to select for language).
const ALL_TRANSLATIONS = {
  // https://www.algolia.com/doc/api-reference/widgets/pagination/react/#widget-param-translations
  pagination: {
    fr: {
      ariaPrevious: 'Page précédente',
      ariaNext: 'Page suivante',
      next: <img src={arrowRight} />,
      previous: <img src={arrowLeft} />,
    },
    en: {},
  },
  // https://www.algolia.com/doc/api-reference/widgets/search-box/react/#widget-param-translations
  searchBox: {
    en: {
      placeholder: 'Type a topic, technology, etc.',
      submitTitle: 'Run your search query',
    },
    fr: {
      placeholder: 'Cherchez un thème, une techno…',
      submitTitle: 'Effectuer la recherche',
    },
  },
}

// Current-language only translation set.
export const TRANSLATIONS = Object.fromEntries(
  Object.entries(ALL_TRANSLATIONS).map(([key, locales]) => [key, locales[LANG]])
)
