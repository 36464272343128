// URL sync (in-out) for search params (query, tags) and pagination (through
// path segments).  Pages are 1-based, their segment can appear anywhere in the
// path, and page-1 segments are omitted on generation as they are the default.
//
// The core logic behind this is detailed in
// https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/react/

import { useEffect, useRef, useState } from 'react'

const REGEX_PAGE_SEGMENT = /\/page-(\d+)\//

// Creates a clean in-origin URL (path + query), omitting any blanks and
// defaults, from the current search state (if any).  Used both for URL syncing
// and URL generation (e.g. in Algolia's `<Pagination/>` component).
export function createURL(state = {}) {
  // Start with the query: search text and tags
  const query = {}
  const tags = state.refinementList?._tags || []
  if (tags.length > 0) {
    query.tags = tags.join(',')
  }

  const text = String(state.query || '').trim()
  if (text) {
    query.text = text
  }

  const queryString =
    Object.keys(query).length > 0
      ? `?${new URLSearchParams(query).toString()}`
      : ''

  // Adjust the existing path to replace/append/strip the page segment
  let path = location.pathname
  const page = `/page-${state.page}/`
  path = path.replace(REGEX_PAGE_SEGMENT, page)
  if (!path.includes(page)) {
    path = (path + page).replace('//', '/')
  }
  path = path.replace('/page-1/', '')

  return path + queryString
}

// The minimum time between two URL syncs, so text typing doesn't clutter the
// history and make the browser jitter/bug.  400ms is a well-established
// typing-related debouncing threshold, recommended by Algolia's docs too.
const DEBOUNCE_TIME = 400

// Custom hook for use in the main search component that encapsulates the
// URL-syncing logic (debounced URL gen, URL-based state restoration at mount,
// and controlled-component state management).
//
// Returns an object with two properties:
// - `reactToSearchState` is a handler function compatible with
//   `<InstantSearch>`'s `onSearchStateChange` prop.  It provides the incoming
//   control and debounced URL gen.
// - `searchState` is the current search state, used as a value for
//   controlled-component behavior.
export function useURLSync() {
  const [searchState, setSearchState] = useState(() =>
    // Compute initial state just once, not every render.
    urlToSearchState(location)
  )
  const debouncedSetStateRef = useRef(null)

  function reactToStateChange(updatedSearchState) {
    clearTimeout(debouncedSetStateRef.current)

    debouncedSetStateRef.current = setTimeout(() => {
      // Raw web History API use!  Who needs React-Router? 😉
      window.history.pushState(
        {},
        document.title,
        createURL(updatedSearchState)
      )
    }, DEBOUNCE_TIME)

    setSearchState(updatedSearchState)
  }

  useEffect(() => setSearchState(urlToSearchState(location)), [location])

  return { reactToStateChange, searchState }
}

// Internal helper to extract the relevant search state from a URL (assuming a
// web Location object).  The reverse operation from `createURL`.
function urlToSearchState({ pathname, search }) {
  const state = {}

  // Start with the path's page segment, if any.  No segment = page 1.
  const page = Number(pathname.match(REGEX_PAGE_SEGMENT)?.[1] || 1)
  state.page = page

  // Extract the tags and search text from the query string
  const query = Object.fromEntries(
    new URLSearchParams(search.slice(1)).entries()
  )
  if (query.tags) {
    state.refinementList = { _tags: query.tags.split(',') }
  }
  if (query.text) {
    state.query = query.text
  }

  return state
}
