// Tag rendering and decoration for refinement lists and custom hits.

// Some tags have a custom visual enhancement, defined in this mapping.
const TAG_IMAGES = {
  formations: 'school',
  git: 'git',
  js: 'js',
  node: 'nodejs',
  training: 'school',
}

// Image decorators for tags (in refinements and hits)
// -------------------------

// Decorates a refinement with a matching image (if one is configured) based on
// the label's transformed text.  This makes refinement filters more visual. As
// this deals with refinements (in a refinement list), `item` is actually a
// Refinement object, with many properties including `label`, etc.
function decorateTag(item) {
  const imageNode = getTagImageNode(item.label)
  if (!imageNode) {
    return item
  }

  return {
    ...item,
    // We need to specify a key because otherwise `<RefinementList>`, or more
    // precisely its wrapped `<List>`, would use the label as key, as as we're
    // using fragments (hence objects) here they'd all have keys saying `[Object
    // object]`, hence duplicates.
    key: item.label,
    label: (
      <>
        {imageNode} <span>{item.label}</span>
      </>
    ),
  }
}

// Utility to turn a tag string into its matching image, if any (otherwise keeps
// the string).  Used both by `decorateTag` and custom hits' tag lists.
export function getTagImageNode(tag, alt = false) {
  const image = TAG_IMAGES[tag.toLowerCase().replace(/\W+/g, '-')]
  if (!image) {
    return null
  }

  const imageURL = `/assets/images/icon/icon-${image}.svg`
  return (
    <img
      className="c-card__tag"
      key={tag}
      src={imageURL}
      loading="lazy"
      alt={alt ? tag : ''}
      title={tag}
    />
  )
}

// Trims, sorts and decorates returned refinements (inside a refinements list).
//
// @param {String} requiredTag=null A tag that was required as a pre-filter on
// all searches.  This strips that tag from the refinements, as all hits will
// have it.
export function tweakTags(requiredTag) {
  return (items) =>
    items
      // A required tag will be filtered out of refinements
      .filter(({ label }) => label !== requiredTag)
      // Force persistent ordering instead of refined-first
      .sort(
        ({ count: c1, label: l1 }, { count: c2, label: l2 }) =>
          Math.sign(c2 - c1) || l1.localeCompare(l2)
      )
      .map((item) => decorateTag(item))
}
